/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */

require('semantic-ui-less/semantic.less');

// handle offset for Gatsby Link anchor hash link
let offsetY = 0
let additionalOffsetY = 110
const getTargetOffset = hash => {
  const id = window.decodeURI(hash.replace(`#`, ``))
  if (id !== ``) {
    const element = document.getElementById(id)
    if (element) {
      let scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop
      let clientTop =
        document.documentElement.clientTop || document.body.clientTop || 0
      let computedStyles = window.getComputedStyle(element)
      let scrollMarginTop =
        computedStyles.getPropertyValue(`scroll-margin-top`) ||
        computedStyles.getPropertyValue(`scroll-snap-margin-top`) ||
        `0px`

      return (
        element.getBoundingClientRect().top +
        scrollTop -
        parseInt(scrollMarginTop, 10) -
        clientTop -
        offsetY
      )
    }
  }
  return null
}

// Logs when the client route changes
export const onRouteUpdate = ({ location, prevLocation }) => {
   //console.log("onRouteUpdate - location", location);
  // console.log("new pathname", location.pathname);
  // console.log("old pathname", prevLocation ? prevLocation.pathname : null);
  // run OneTrust SDK init when route changes
  if (window.OneTrust && window.OneTrust.initializeCookiePolicyHtml) {
    //console.log('onRouteUpdate - OneTrust - initializeCookiePolicyHtml');
    window.OneTrust.initializeCookiePolicyHtml();
  }
}

// handle offset for Gatsby Link anchor hash link 
export const shouldUpdateScroll = ({ routerProps: { location } }) => {
  //console.log("shouldUpdateScroll - location", location);
  const offset = getTargetOffset(location.hash);
  //console.log('shouldUpdateScroll - offset', offset);
  const additionalOffset = offset-additionalOffsetY
  //console.log('shouldUpdateScroll - additionalOffset', additionalOffset);
  setTimeout(() => {
    window.scrollTo({top: additionalOffset, left: 0, behavior: 'smooth'});
  }, 0);
  return offset !== null ? [0, additionalOffset] : true
  //return offset !== null ? [0, offset] : true
}
export const onInitialClientRender = (_, pluginOptions) => {
  requestAnimationFrame(() => {
    const offset = getTargetOffset(window.location.hash)
    //console.log('onInitialClientRender - offset', offset);
    const additionalOffset = offset-additionalOffsetY
    //console.log('onInitialClientRender - additionalOffset', additionalOffset);
    if (offset !== null) {
      window.scrollTo(0, additionalOffset)
    }
  })
}
