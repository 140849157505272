exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-templates-pages-js": () => import("./../../../src/templates/pages.js" /* webpackChunkName: "component---src-templates-pages-js" */),
  "component---src-templates-pages-news-item-js": () => import("./../../../src/templates/pages-newsItem.js" /* webpackChunkName: "component---src-templates-pages-news-item-js" */),
  "component---src-templates-pages-press-release-js": () => import("./../../../src/templates/pages-pressRelease.js" /* webpackChunkName: "component---src-templates-pages-press-release-js" */),
  "component---src-templates-pagination-index-news-item-js": () => import("./../../../src/templates/paginationIndex-newsItem.js" /* webpackChunkName: "component---src-templates-pagination-index-news-item-js" */),
  "component---src-templates-pagination-index-press-release-js": () => import("./../../../src/templates/paginationIndex-pressRelease.js" /* webpackChunkName: "component---src-templates-pagination-index-press-release-js" */)
}

